import { batch } from 'react-redux'
import { PageValidationError } from 'common/errors/PageValidationError'
import { getFilteredEntitiesIdsByPageType } from 'common/utils/entityUtils'
import {
  FAIL,
  FETCH_EXIT_URL,
  FETCH_INPUT_TYPES,
  FETCH_PAGE,
  FETCH_PAGE_FILES,
  FETCH_USER,
  PAGE_VALIDATION_ERROR,
  PAGE_VALIDATION_ERROR_RESET,
  PREVIEW_PAGE,
  RESET_PAGE_ERROR,
  SAVE_PAGE,
  SUCCESS,
  TOGGLE_DOUBLE_OPT_IN,
  FETCH_COLORS,
  FETCH_HAS_FACEBOOK_CONVERSION_PIXEL,
} from 'client/actionTypes'
import * as pageApi from 'client/api/pageApi'
import { validatePage, validateFormInputs } from 'client/utils/validators'
import fixIsMasterBlockRootAndParentIdCommandForClient from 'tools/commands/fixIsMasterBlockRootAndParentIdCommand/fixIsMasterBlockRootAndParentIdCommandForClient'

export const fetchPageDataFailure = error => ({
  type: FETCH_PAGE + FAIL,
  payload: error,
})

const fetchPageSuccess = content => ({
  type: FETCH_PAGE + SUCCESS,
  payload: content,
})

const fetchColorsSuccess = content => ({
  type: FETCH_COLORS + SUCCESS,
  payload: content,
})

const fetchHasFacebookConversionPixelSuccess = content => ({
  type: FETCH_HAS_FACEBOOK_CONVERSION_PIXEL + SUCCESS,
  payload: content,
})

const fetchUserSuccess = user => {
  if (!user) {
    return {}
  }

  if (typeof window.Rollbar !== 'undefined') {
    window.Rollbar.configure({
      payload: {
        person: {
          id: user.id,
          username: user.name,
          email: user.email,
        },
      },
    })
  }

  return {
    type: FETCH_USER + SUCCESS,
    payload: user,
  }
}

const fetchInputTypesSuccess = inputTypes => ({
  type: FETCH_INPUT_TYPES + SUCCESS,
  payload: inputTypes,
})

const fetchExitUrl = inputTypes => ({
  type: FETCH_EXIT_URL,
  payload: inputTypes,
})

const fetchFilesSuccess = files => ({
  type: FETCH_PAGE_FILES,
  payload: files,
})

export const savePageSuccess = () => ({
  type: SAVE_PAGE,
})

export const pageValidationFail = errors => ({
  type: PAGE_VALIDATION_ERROR,
  payload: errors,
})

export const previewPageFail = error => ({
  type: PREVIEW_PAGE + FAIL,
  payload: error,
})

export const savePageFail = error => ({
  type: SAVE_PAGE + FAIL,
  payload: error,
})

export const fetchPageDataSuccess = data => async dispatch => {
  batch(() => {
    dispatch(fetchPageSuccess(data.page))
    dispatch(fetchFilesSuccess(data.files))
    dispatch(fetchInputTypesSuccess(data.fields))
    if (data.user) {
      dispatch(fetchUserSuccess(data.user))
    }
    if (data.colors) {
      dispatch(fetchColorsSuccess(data.colors))
    }
    if (data.has_facebook_conversion_pixel) {
      dispatch(
        fetchHasFacebookConversionPixelSuccess(
          data.has_facebook_conversion_pixel,
        ),
      )
    }
    dispatch(fetchExitUrl(data.exitUrl))
  })
}

export const previewPage = isTemplate => (dispatch, getState) => {
  const { present } = getState().page

  const errors = validatePage(present)

  if (errors.length > 0) {
    throw new PageValidationError(errors)
  }

  return pageApi.previewPage(present, isTemplate)
}

export const savePage = isTemplate => (dispatch, getState) => {
  const {
    page: { present },
    payment,
    inputTypes,
  } = getState()

  const errors = validatePage(present, payment)

  if (errors.length > 0) {
    throw new PageValidationError(errors)
  }

  if (payment.paymentMethods.length > 0) {
    validateFormInputs(present, payment.paymentMethods, inputTypes)
  }
  const page = { ...getState().page.present }

  page.entities = Object.values(page.entities).reduce((acc, entity) => {
    if (entity.isReadOnly) {
      return acc
    }

    return {
      ...acc,
      [entity.id]: entity,
    }
  }, {})

  const excludedEntitiesIds = getFilteredEntitiesIdsByPageType(
    page.entities,
    page.type,
  )

  if (excludedEntitiesIds.length > 0) {
    page.entities = Object.entries(page.entities).reduce(
      (acc, [entityId, entity]) => {
        if (excludedEntitiesIds.includes(entityId)) {
          return { ...acc }
        }
        return { ...acc, [entityId]: entity }
      },
      {},
    )
  }
  page.entities = fixIsMasterBlockRootAndParentIdCommandForClient(page.entities)[0]

  return pageApi.savePage(page, isTemplate)
}

export const resetPageError = () => dispatch => {
  dispatch({
    type: RESET_PAGE_ERROR,
  })
}

export const resetPageValidationError = () => dispatch => {
  dispatch({
    type: PAGE_VALIDATION_ERROR_RESET,
  })
}

export const toggleDoubleOptIn = () => dispatch => {
  dispatch({
    type: TOGGLE_DOUBLE_OPT_IN,
  })
}

import ServerPageInterface from 'server/types/ServerPageInterface'

/* NOTE: for fixing https://app.shortcut.com/systeme/story/51271/adding-masterblock-creates-duplicates-on-page-header-and-footer
 * Remove duplicated entities
 */
export default function removeDuplicatedEntitiesCommand(
  page: ServerPageInterface,
): [ServerPageInterface, boolean] {
  let needChangeContent = false
  const originalEntities = page.content.entities

  Object.values(originalEntities).forEach(entity => {
    if (entity.childIds) {
      const newChildIds = entity.childIds
        .filter(childId => {
          if (originalEntities[childId].parentId === entity.id) {
            return true
          } else {
            needChangeContent = true
            return false
          }
        })
        .filter((childId, index) => {
          // remove duplications in childIds
          if (entity.childIds?.indexOf(childId) === index) {
            return true
          } else {
            needChangeContent = true
            return false
          }
        })
      originalEntities[entity.id].childIds = newChildIds
    }
  })

  return [page, needChangeContent]
}

import { isWithChildIds } from 'common/utils/entityUtils'
import ServerPageInterface from 'server/types/ServerPageInterface'

/* NOTE: for fixing https://app.shortcut.com/systeme/story/51271/adding-masterblock-creates-duplicates-on-page-header-and-footer
 * removing ids in childIds that do not exist
 */
export default function removeNonexistentIdFromChildIdsCommand(
  page: ServerPageInterface,
): [ServerPageInterface, boolean] {
  let needChangeContent = false
  const originalEntities = page.content.entities
  const entitiesKeys = Object.keys(originalEntities)
  Object.values(originalEntities).forEach(entity => {
    if (isWithChildIds(entity)) {
      const newChildIds = entity.childIds?.filter(childId => {
        if (entitiesKeys.includes(childId)) {
          return true
        } else {
          needChangeContent = true
          return false
        }
      })

      originalEntities[entity.id].childIds = newChildIds
    }
  })

  return [page, needChangeContent]
}

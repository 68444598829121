import ServerPageInterface from 'server/types/ServerPageInterface'
import fixIsMasterBlockRootAndParentIdCommandForClient from './fixIsMasterBlockRootAndParentIdCommandForClient'

// NOTE: for fixing https://app.shortcut.com/systeme/story/51271/adding-masterblock-creates-duplicates-on-page-header-and-footer
export default function fixIsMasterBlockRootAndParentIdCommand(
  page: ServerPageInterface,
): [ServerPageInterface, boolean] {
  const originalEntities = page.content.entities
  const [entities, needChangeContent] =
    fixIsMasterBlockRootAndParentIdCommandForClient(originalEntities)

  return [
    {
      ...page,
      content: {
        ...page.content,
        entities,
      },
    },
    needChangeContent,
  ]
}

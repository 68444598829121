import React from 'react'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { FieldInterface } from 'common/types/entities/field-interface'
import { Select } from 'client/components/core/Sidebar/components/Settings/components'
import Checkbox from 'client/components/core/Sidebar/components/Settings/components/Checkbox'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import IconPickerWithRemoveButton from 'client/components/core/Sidebar/components/Settings/components/IconPickerWithRemoveButton'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input/Input'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import {
  FontSize,
  LineHeight,
  ShadowStyle,
  TextAlignStyle,
} from 'client/components/core/Sidebar/components/Settings/styles'
import Border from 'client/components/core/Sidebar/components/Settings/styles/Border'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useInputTypes, usePage } from 'client/store'
import inputTypesSelectors from 'client/store/inputTypes/inputTypesSelectors'
import { pageSelectors } from 'client/store/page/pageSelectors'

const FieldSettings = ({ entity }: { entity: FieldInterface }) => {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<FieldInterface>()
  const pageType = usePage(pageSelectors.getPageType)
  const isLoginPage = pageType === PageTypeEnum.CustomLogin
  const isCountryType = entity.slug === FieldSlugEnum.Country
  const inputTypes = useInputTypes(
    inputTypesSelectors.getIdNameListWithEmailAndNameFirst,
  )
  const preparedInputTypes: Record<string, string> = {}
  inputTypes.forEach(inputType => {
    preparedInputTypes[inputType.slug] = inputType.name
  })

  const isMandatoryType = entity.slug === FieldSlugEnum.Email
  const isOptionalType =
    entity.slug === FieldSlugEnum.TaxNumber ||
    entity.slug === FieldSlugEnum.CompanyName

  const isOptionalSettingAvailable =
    !isLoginPage && !isMandatoryType && !isOptionalType

  const updateInputType = (newSlug: string) => {
    if (!newSlug) return

    const inputTypeObj = inputTypes.find(obj => obj.slug === newSlug)

    const slug: FieldSlugEnum = inputTypeObj?.slug as FieldSlugEnum
    const placeholder = inputTypeObj?.name
    let optional = false

    if (
      newSlug === FieldSlugEnum.CompanyName ||
      newSlug === FieldSlugEnum.TaxNumber
    ) {
      optional = true
    }

    updateEntity({
      ...entity,
      slug,
      placeholder,
      optional,
    })
  }

  return (
    <>
      {!isLoginPage && (
        <Select
          labelText="Input type"
          update={updateInputType}
          value={entity.slug}
          firstOption="Select a type"
          choiceList={preparedInputTypes}
          needTranslate={false}
        />
      )}
      {!isCountryType && (
        <Input
          label="entity_settings.field.placeholder.label"
          value={entity.placeholder}
          update={updateProp('placeholder')}
          type="text"
        />
      )}
      <TextAlignStyle
        textAlign={entity.textAlign ?? 'center'}
        mobileTextAlign={entity.mobileTextAlign ?? entity.textAlign ?? 'center'}
        update={updateProp('textAlign')}
        mobileUpdate={updateProp('mobileTextAlign')}
      />
      <ColorPicker
        label="entity_settings.field.placeholder_color"
        update={
          isMobile
            ? updateProp('mobilePlaceholderColor')
            : updateProp('placeholderColor')
        }
        color={
          isMobile ? entity.mobilePlaceholderColor : entity.placeholderColor
        }
      />
      {isOptionalSettingAvailable && (
        <Checkbox
          labelText="entity_settings.field.optional"
          helpMessage="entity_settings.field.optional_popover"
          value={entity.optional}
          update={updateProp('optional')}
        />
      )}
      <GroupTitle>typography</GroupTitle>
      <FontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <LineHeight
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
      />
      {!isCountryType && (
        <IconPickerWithRemoveButton
          label="entity_options.icon_picker.title"
          value={entity.iconClassName}
          update={updateProp('iconClassName')}
        />
      )}
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        label="settings_styles.color.label"
        update={isMobile ? updateProp('mobileColor') : updateProp('color')}
        color={isMobile ? entity.mobileColor : entity.color}
      />
      <ColorPicker
        label="settings_styles.background_color.label"
        update={
          isMobile
            ? updateProp('mobileBackgroundColor')
            : updateProp('backgroundColor')
        }
        color={isMobile ? entity.mobileBackgroundColor : entity.backgroundColor}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <Border
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
        border={isMobile ? entity.mobileBorder : entity.border}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default FieldSettings

import fixIsMasterBlockRootAndParentIdCommand from 'tools/commands/fixIsMasterBlockRootAndParentIdCommand/fixIsMasterBlockRootAndParentIdCommand'
import migrateAudioCommand from 'tools/commands/migrate/Audio/migrateAudioCommand'
import migrateBulletListCommand from 'tools/commands/migrate/BulletList/migrateBulletListCommand'
import migrateCheckboxCommand from 'tools/commands/migrate/Checkbox/migrateCheckboxCommand'
import migrateContentBoxCommand from 'tools/commands/migrate/ContentBox/migrateContentBoxCommand'
import migrateCountdownCommand from 'tools/commands/migrate/Countdown/migrateCountdownCommand'
import migrateFormInputCommand from 'tools/commands/migrate/FormInput/migrate-form-input-command'
import migrateHorizontalLineCommand from 'tools/commands/migrate/HorizontalLine/migrateHorizontalLineCommand'
import migrateInlineCommand from 'tools/commands/migrate/Inline/migrateInlineCommand'
import migrateLanguageSwitcherCommand from 'tools/commands/migrate/LanguageSwitcher/migrateLanguageSwitcherCommand'
import migrateRawHtmlCommand from 'tools/commands/migrate/RawHtml/migrateRawHtmlCommand'
import migrateContactUsRecaptchaElementCommand from 'tools/commands/migrate/Recaptcha/migrateContactUsRecaptchaElementCommand'
import migrateRowColumnCommand from 'tools/commands/migrate/RowColumnLayout/migrateRowColumnCommand'
import migrateSalesRedirectionButtonCommand from 'tools/commands/migrate/SalesRedirectionButton/migrateSalesRedirectionButtonCommand'
import migrateSectionCommand from 'tools/commands/migrate/Section/migrateSectionCommand'
import migrateUpsellButtonsCommand from 'tools/commands/migrate/UpsellButton/migrateUpsellButtonsCommand'
import migrationBodyCommand from 'tools/commands/migrate/body/migrate-body-command'
import migrateButtonCommand from 'tools/commands/migrate/button/migrate-button-command'
import migrateCarouselCommand from 'tools/commands/migrate/carousel/migrateCarouselCommand'
import migrateImageCommand from 'tools/commands/migrate/image/migrateImageCommand'
import migrateMenuCommand from 'tools/commands/migrate/menu/migrateMenuCommand'
import migratePhysicalProductCommand from 'tools/commands/migrate/migratePhysicalProduct/migratePhysicalProductCommand'
import migrateOfferPriceCommand from 'tools/commands/migrate/offerPrice/migrateOfferPriceCommand'
import migratePaymentButtonCommand from 'tools/commands/migrate/paymentButton/migratePaymentButtonCommand'
import migratePaymentMethodCommand from 'tools/commands/migrate/paymentMethod/migratePaymentMethodCommand'
import migrateRemotePopupCommand from 'tools/commands/migrate/remote-popup/migrate-remote-popup-command'
import migrateTextToTsCommand from 'tools/commands/migrate/text/migrateTextToTsCommand'
import migrateVideoCommand from 'tools/commands/migrate/video/migrateVideoCommand'
import removeDuplicatedMasterBlocksCommand from 'tools/commands/removeDuplicatedEntitiesCommand'
import removeNonexistentIdFromChildIdsCommand from 'tools/commands/removeNonexistentIdFromChildIdsCommand'
import setParentIdSafeCommand from 'tools/commands/setParentIdSafeCommand'
import ServerPage from 'server/types/ServerPageInterface'
import migrateAgreementCommand from '../../tools/commands/migrate/Agreement/migrateAgreementCommand'
import migrateOrderBumpCommand from '../../tools/commands/migrate/orderBump/migrate-order-bump-command'

export type MigrationCommand = (page: ServerPage) => [ServerPage, boolean]

export const DEFAULT_AUTOMIGRATE_COMMAND_LIST: MigrationCommand[] = [
  removeNonexistentIdFromChildIdsCommand,
  setParentIdSafeCommand,
  fixIsMasterBlockRootAndParentIdCommand,
  removeDuplicatedMasterBlocksCommand,
  // from ts
  migrateRawHtmlCommand,
  migrateCheckboxCommand,
  migrateBulletListCommand,
  migrateCountdownCommand,
  migratePaymentButtonCommand, // @TODO remove from default list
  migrateAgreementCommand,
  migrateInlineCommand,
  migrateRemotePopupCommand,
  migrateButtonCommand,
  migrateFormInputCommand,
  migrationBodyCommand,
  // from js
  migrateAudioCommand,
  migrateHorizontalLineCommand,
  migrateSalesRedirectionButtonCommand,
  migrateUpsellButtonsCommand, // @TODO remove from default list
  migrateMenuCommand,
  migrateContentBoxCommand,
  migrateLanguageSwitcherCommand,
  migrateContactUsRecaptchaElementCommand,
  migrateRowColumnCommand,
  migrateSectionCommand,
  migrateCarouselCommand,
  migrateImageCommand,
  migrateOfferPriceCommand, // @TODO remove from default list
  migratePaymentMethodCommand, // @TODO remove from default list
  migratePhysicalProductCommand, // @TODO remove from default list
  migrateTextToTsCommand,
  migrateVideoCommand,
  migrateOrderBumpCommand, // @TODO remove from default list
]

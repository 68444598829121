import { EntityId, MasterBlockId } from 'common/types/entities/EntityInterface'
import { isWithIsMasterBlockRoot } from 'common/utils/entityUtils'
import { EntitiesType } from 'server/types/ServerPageInterface'

/* NOTE: for fixing https://app.shortcut.com/systeme/story/51271/adding-masterblock-creates-duplicates-on-page-header-and-footer
 * removing isMasterBlockRoot for all entities of masterBlock except root
 */
export default function fixIsMasterBlockRootAndParentIdCommandForClient(
  originalEntities: EntitiesType,
): [EntitiesType, boolean] {
  let needChangeContent = false
  const originalEntitiesValues = Object.values(originalEntities)
  const masterBlocksEntities: Record<MasterBlockId, EntityId[]> = {}
  originalEntitiesValues.forEach(entity => {
    if (entity.masterBlockId) {
      if (entity.masterBlockId in masterBlocksEntities) {
        masterBlocksEntities[entity.masterBlockId].push(entity.id)
      } else {
        masterBlocksEntities[entity.masterBlockId] = [entity.id]
      }
    }
  })

  Object.values(masterBlocksEntities).forEach(masterBlockEntitiesIds => {
    masterBlockEntitiesIds.forEach(masterBlockEntityId => {
      if (isWithIsMasterBlockRoot(originalEntities[masterBlockEntityId])) {
        const shouldNotHaveIsMasterBlockRoot = masterBlockEntitiesIds.some(
          masterBlockEntityId2 =>
            originalEntities[masterBlockEntityId].parentId ===
            masterBlockEntityId2,
        )
        if (shouldNotHaveIsMasterBlockRoot) {
          needChangeContent = true
          delete originalEntities[masterBlockEntityId].isMasterBlockRoot
        }
      }
    })
  })

  return [originalEntities, needChangeContent]
}

import ServerPageInterface from 'server/types/ServerPageInterface'

export default function setParentIdSafeCommand(
  page: ServerPageInterface,
): [ServerPageInterface, boolean] {
  let needChangeContent = false
  const originalEntities = page.content.entities
  Object.values(originalEntities).forEach((entity, _, entities) => {
    if (!entity.parentId) {
      const parentEntity = entities.find(e => e.childIds?.includes(entity.id))
      if (parentEntity) {
        needChangeContent = true
        originalEntities[entity.id].parentId = parentEntity.id
      }
    }
  })

  return [page, needChangeContent]
}
